export const autoReloadMesssages = {
    newVersionText: 'We just made an improvement to CTBids',
    clickToRefreshText: 'Please click to accept'
}

export const authErrorMessage = {
    facebookEmailEnable: 'Please enable share email option in facebook account setting to enable sign in with CTBids using facebook account / Facebook login using mobile number is not supported in CTBids',
    appleEmailEnable: 'Please enable share email option in apple account setting to enable sign in with CTBids using apple account',
    facebookUnavailable: 'Facebook login is currently unavailable, please try again after sometime'
}

