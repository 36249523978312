import React, { Component, useEffect, useState } from "react";
import { connect } from 'react-redux';
import store from 'features/store';
import { MyAccountMenuList, ToggleSideBar, removeSideBar } from 'features/components/base/auth-pages/MyAccount/MyAccountMenuList';
import { Button } from "react-bootstrap";
import pmservice from "shared/services/pmservice";
import './worthpoint.scss'
import { isAuthorized, logout } from "shared/settings/auth";
import { addDataLayer } from "shared/utils/utils";
import { Helmet } from "react-helmet";


const WorthPointfn = (props) => {
  const [pageLoading, setPageLoading] = useState(true)
  const [myWorthPoint, setMyWorthPoint] = useState([]);

  useEffect(() => {
    if(isAuthorized()) {
      if (props.user.id) {
        getWorthPoint();
      } else {
        store.dispatch({ type: 'TOGGLE_SESSION_MODAL', payload: true });
        logout(props.history, false);
      }
    } else {
      store.dispatch({ type: 'TOGGLE_SIGNIN_MODAL', payload: { open: true, mode: 'Sign-In' } });
    }
  }, [props.user.id]);

  useEffect(() => {
    let dataLayerData = {
      'event': 'pm_worthpoint',
      'buyr_id': props.user.id
    }
    addDataLayer(dataLayerData);
  }, []);

  const getWorthPoint = () => {
    pmservice.getContent('WorthPoint', '').then((data) => {
      let worthpointdata = data.data.data[0].jsoncontent;
      let myworthpointdata = JSON.parse(worthpointdata);
      setMyWorthPoint(myworthpointdata);
      setPageLoading(false)
    })
    .catch((error) => {
      if (error?.status === 401) {
        store.dispatch({ type: 'TOGGLE_SESSION_MODAL', payload: true });
        logout(props.history, false);
      }
      setPageLoading(false)
    });
  }

  const renderDescription = (description) => {
    const parts = description.split('#HREF');
    return parts.map((data, index) => {
      if (index === 1) {
        const splitValue = data.split(',');
        if (splitValue) {
          const [telNumber, displayNumber] = splitValue[0].split('#');
          return (
            <>
              <a style={{ color: '#00263E' }} href={`tel:${telNumber}`}>{displayNumber}</a>
              {splitValue[1]}
            </>
          );
        }
      } else if (index === 2) {
        const [displayText, url] = data.split('#');
        return (
          <a href={url} style={{ color: '#00263E' }} target="_blank">
            {displayText}
          </a>
        );
      } else if (data.includes('#EMAILREF#')) {
        const emailParts = data.split('#EMAILREF#');
        return emailParts.map((part, idx) => {
          if (idx === 1) {
            let parts = part.split('#')
            return (

              <a
                key={`email-${idx}`}
                style={{ color: '#00263E', fontWeight:'bold',textDecoration: 'none !important' }}
                href={`mailto:${parts[0]}`}
              >
                {parts[1]}
              </a>
            );
          }
          return part;
        });
      } else {
        return data;
      }
    });
  };
  
  

  return (
    <>
      <Helmet>
        <title>CTBids | Premier WorthPoint</title>
      </Helmet>
      {pageLoading ?
        <div className="h-100 d-flex align-items-center justify-content-center">
          <img src={`${process.env.PUBLIC_URL}/static/icons/table/loader.gif`} className="loader-icon" alt="loader-icon"></img>
        </div> :
        <div className="worthpoint" style={{ padding: '30px', overflow: 'auto' }}>
          <div className="mb-4" style={{ display: "flex", alignItems: "center" }}>
            <img src={myWorthPoint?.logo} style={{ width: '80px' }} />
            <h1 style={{ marginLeft: '10px', fontWeight: 'bold' }}>{myWorthPoint?.title}</h1>
          </div>
          {myWorthPoint?.action?.title ? <Button style={{ marginTop: '10px', backgroundColor: '#1D8296', padding: '10px', borderRadius: '10px', border: 'none' }} onClick={()=>window.open(myWorthPoint?.action?.navigatesTo)}>{myWorthPoint?.action?.title}</Button> : ''}
          <div className="worthpoint-content">
            {myWorthPoint?.body?.map((data, index) => (
              <div key={index} style={{ display: "flex" }}>
                <div className="p-1" style={{ width: "90%", border: '1px solid white', backgroundColor: 'white' }}>
                  <div className="mt-3" style={{ fontWeight: 'bold', fontSize: '20px' }}>{data?.title}</div>

                  {data?.colorAndText &&   <div style={{ display: 'flex', marginTop: '10px', flexWrap: "wrap" }}>
                      {data?.colorAndText.map((item, idx) => (
                        <div key={idx} style={{ display: "flex", alignItems: "center", margin: "5px 0", width: "100%" }}>
                          <div style={{ width: "20px", height: "20px", backgroundColor: item.color, marginRight: "10px" }}></div>
                          <div>{item.title}</div>
                        </div>
                      ))}
                    </div>}

                   { Array.isArray(data?.description) ? <> {data?.description.map((desc, idx) => (
                        <p key={idx} className="mt-3" dangerouslySetInnerHTML={{ __html: desc }}></p>
                      ))}
                      </> :
                      <p className="mt-3" >{renderDescription(data.description)}</p>}
                      
                </div>
              </div>
            ))}
          </div>
          <div className="mt-5">
            {myWorthPoint?.footer?.description}
          </div>
        </div>}
    </>
  );
};

class WorthPoint extends Component {
  constructor(props) {
    super(props);
    this.buyrId = this.props.user.id
  }

  componentDidMount() {
    store.dispatch({ type: 'SIDEBAR_MENU_LIST', payload: { menuList: MyAccountMenuList(), withSpan: false } });
    ToggleSideBar();
    window.addEventListener('resize', ToggleSideBar);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', ToggleSideBar);
    removeSideBar();
  }

  render() {
    return <WorthPointfn {...this.props} />
  }
}

export default connect(state => state)(WorthPoint);
